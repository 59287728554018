/*=============================================================================================
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Your Name
==============================================================================================*/
/* helper
----------------------------------------------------------------------------------------------*/
@import "../helper/sass-lib";
// @import "../helper/slick";       // import your slick slider style
// @import "../milligram/Color";    // import your general color from milligram

/* general
----------------------------------------------------------------------------------------------*/
@import "../component/layout";
@import "../component/header-white";
@import "general";
// @import "../component/popup";

.wrap-result-search {
  h4 {
      font-size: 1.75rem;
      color: #0F3B7D;
      font-weight: 700;
  }
  h5 {
      color: #4D5664;
      margin-bottom: 45px;
  }
}
.list-result {
  .item-result {
    position: relative;
    margin-bottom: 40px;
    h6 {
      font-size: 1.25rem;
      margin-bottom: 10px;
      color: #1A2537;
      a {
        color: #1A2537;
      }
      &:hover {
        a {
          color: #0F3B7D;
        }
      }
    }
    p {
      line-height: 1.75;
      margin-bottom: 15px;
    }
  }
}

body.full-view {
  header,footer {
    display: none;
  }
}
.wrap-full-view {
  position: relative;
  overflow: hidden;
  figure {
    height: 100vh;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .wrapper {
    position: relative;
    height: 100%;
    max-width: 1280px;
  }
}
.under,
.page-404 {
  .img {
    position: absolute;
    left: 140px;
    top: auto;
    bottom: -10px;
  }
  .caption-absolute {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    .wrapper {
      display: flex;
      justify-content: flex-end;
    }
    .text {
      width: 560px;
      > img {
        margin-bottom: 20px;
      }
    }
    h1 {
      font-size: 4.2rem;
      color: #fff;
      font-weight: 700;
    }
    h2 {
      font-size: 3.8rem;
      color: #fff;
      font-weight: 700;
    }
    p {
      color: #fff;
      line-height: 1.65;
      font-size: 1.25rem;
      opacity: .8;
      // padding-right: 30px;
    }
    .ico-text {
      padding-left: 30px;
      margin-bottom: 10px;
      color: #fff;
      font-size: 1rem;
      opacity: .8;
      a {
        color: #fff;
      }
    }
  }
}
.page-404 {
  .caption-absolute {
    .wrapper {
      justify-content: flex-start;
    }
    .text {
      text-align: center;
      width: 350px;
      padding-left: 100px;
      box-sizing: content-box;
      h1 {
        font-size: 8.75rem;
        line-height: 1;
      }
      h4 {
        font-size: 1.875rem;
        color: #fff;
      }
      p {
        font-size: 1.073rem;
      }
    }
  }
  .img {
    left: auto;
    right: 150px;
  }
}
.sitemap-page {
  margin-top: 100px;
  min-height: calc(100vh - 100px - 386px);
}
.sitemap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -25px;
  > ul {
      margin: 0 25px 100px;
      width: calc(33.33% - 50px);
      li {
          margin-bottom: 10px;
          &.other {
            margin-top: 30px;
          }
          > a {
              color: #1A2537;
              display: inline-block;
              font-size: 24px;
              font-weight: bold;
              line-height: 36px;
              transition: .2s all ease;
              &:hover {
                  color: #00A9E2;
                  transition: .2s all ease;
              }
          }
          ul {
              margin: 10px 0 0;
              li {
                  display: block;
                  margin-bottom: 5px;
                  a {
                      color: #1A2537;
                      font-size: 17px;
                      font-weight: normal;
                      line-height: 21px;
                  }
                  ul {
                    margin-left: 20px;
                  }
              }
          }
      }
  }
}

/* responsive desktop
----------------------------------------------------------------------------------------------*/
// @media all and (max-width: 1600px) {}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media (max-width: 1280px) {
  .sitemap-page {
    min-height: calc(100vh - 70px - 368px);
  }
}

@media all and (max-width: 1200px) {
  .under, .page-404{
    .img{
      width: 460px;
      left: 30px;
    }
    .caption-absolute{
      .text{
        width: 490px;
      }
      h1{
        font-size: 3.375rem;
      }
      p{
        font-size: 1.25rem;
      }
    }
  }
  .under .img {
    width: 460px;
  }
  .page-404{
    .img{
      left: unset;
      right: 0;
      width: 460px;
    }
    .caption-absolute{
      .text{
        padding: 0;
        h1{
          font-size: 6.25rem;
        }
        h4{
          font-size: 1.25rem;
        }
      }
    }
  }
  .sitemap-page {
    margin-top: 70px;
    min-height: calc(100vh - 70px - 361px);
  }
}

/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .wrap-result-search{
    h4{
      font-size: 1.063rem;
      margin-bottom: 7px;
    }
    h5{
      font-size: 0.938rem;
      margin-bottom: 16px;
    }
  }
  .list-result{
    .item-result{
      margin-bottom: 30px;
      h6{
        font-size: 1.125rem;
      }
    }
  }
  .under, .page-404{
    .img{
      width: 400px;
      left: 30px;
    }
    .caption-absolute{
      top: 150px;
      transform: unset;
      .wrapper{
        display: block;
        margin: 0 115px;
      }
      .text{
        width: 370px;
      }
    }
  }
  .page-404{
    .img{
      left: unset;
      right: 50%;
      transform: translateX(50%);
      width: 470px;
    }
    .caption-absolute{
      .text{
        padding: 0;
        margin: 0 auto;
        h1{
          font-size: 6.25rem;
        }
        h4{
          font-size: 1.25rem;
        }
      }
    }
  }
  .sitemap-page {
    min-height: calc(100vh - 70px - 323px);
  }
  .sitemap > ul {
    margin-bottom: 50px;
    width: calc(50% - 50px);
    li {
      a {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .pagging ul li{
    margin: 0;
  }
  .under, .page-404{
    .img{
      width: 279px;
      left: 30px;
    }
    .caption-absolute{
      top: 100px;
      transform: unset;
      z-index: 9;
      .wrapper{
        display: block;
        margin: 0 35px;
      }
      .text{
        width: auto;
        > h1 {
          margin-bottom: 10px;
        }
      }
      h1{
        font-size: 2.6rem;
      }
      p, .ico-text{
        font-size: 0.938rem;
        padding-right: 0;
      }
    }
  }
  .under .caption-absolute {
    top: 30px;
  }
  .page-404{
    .img{
      left: unset;
      right: 50%;
      transform: translateX(50%);
    }
    .caption-absolute{
      .text{
        padding: 0;
        margin: 0 auto;
        h1{
          font-size: 4rem;
        }
        h4{
          font-size: 1.5rem;
        }
        p{
          font-size: 1rem;
          padding-right: 0;
        }
      }
    }
  }
  .sitemap-page {
    min-height: calc(100vh - 70px - 353px);
  }
  .sitemap {
    margin: 0;
    > ul {
      margin: 0;
      width: 100%;
      li.other {
        margin-top: 0;
      }
    }
  }
}