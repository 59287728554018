header {
  .logo{
    .color{
      opacity: 1;
    }
    .white{
      opacity: 0;
    }
  }
  .right-side h6 {
    color: #35434F;
  }
  .burger-nav span {
    background: #35434F;
  }
  .main-header {
    background: #fff;
    transition: none;
    &:before {
      display: none;
    }
  }
  .wrapper .right-header {
    nav {
      position: relative;
      &:after {
        background: #35434F;
        content: '';
        height: 25px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: .2s all ease-out;
        width: 1px;
      }
      ul li a {
        color: #35434F;
        transition: none;
      }
    }
    .language label {
      color: #35434F;
      &:after {
        content: url(/images/material/ico-arr-top-black.svg);
      }
    }
    .search-header .ico {
      background: url(/images/material/ico-search-gray.svg) no-repeat center;
      transition: none;
    }
  }
}
/* responsive desktop
----------------------------------------------------------------------------------------------*/
// @media all and (max-width: 1600px) {}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1100px) {
  header{
    .right-side{
      h6{
        color: #0B2C5E;
      }
    }
    .burger-nav{
      span{
        background: #0B2C5E;
      }
    }
    .wrapper{
      .right-header{
        nav{
          ul{
            li{
              a{
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
// @media all and (max-width: 1023px) {}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
// @media all and (max-width: 767px) {}